import React from 'react';
import Nav from '../components/Nav/Nav';

export default function aboutPage() {
  return (
    <div>
      <Nav />
      <h1>About Corina and Andre</h1>
      <p>
        I don't think we need this page since the site will be a one pager for
        now. But, this shows how you can add full pages to the site, but just
        including a file.
      </p>
    </div>
  );
}
